<av-card [popup]="true" vAlign="top">
  <!-- [@slideInOut]="true" // caused page to reload for some reason -->
  <av-card-header
    (close)="cancelSMS()"
    subtitle="Please fill in the phone number"
    translate
  >
    Customer's Phone number
  </av-card-header>
  <form #f="ngForm" (submit)="sendSMS(f.form)">
    <av-card-body>
      <av-control-row type="text">
        <input
          required
          placeholder="{{ 'ex: +30123456789' | translate }}"
          #formPhone="ngModel"
          [pattern]="phonePattern"
          name="phone-number"
          [(ngModel)]="phoneNumber"
          avInput
          type="text"
          data-tid="conference/input-number"
        />
      </av-control-row>
      <div
        class="form-error"
        *ngIf="formPhone.touched && formPhone.hasError('required')"
        translate
      >
        Required
      </div>
      <div
        class="form-error"
        *ngIf="formPhone.touched && formPhone.hasError('pattern')"
        translate
      >
        Not a valid phone number
      </div>
    </av-card-body>
    <av-card-footer>
      <button
        avButton
        type="submit"
        [loading]="isPostingSMS"
        [disabled]="isPostingSMS"
        size="block"
        color="accent"
        data-tid="conference/send-sms"
        translate
      >
        Send SMS
      </button>
    </av-card-footer>
  </form>
</av-card>
