import {
  AgentParam,
  AuviousRtcService,
  ConversationOriginEnum,
  IApplicationConfig,
  IOpenIDConnectApplicationConfig,
  IPagedResponse,
  ITalkdeskApplicationConfig,
  IUser,
  IUserDetails,
  PublicParam,
  TalkdeskRequestBuilder,
  UserService,
} from "../../../../core-ui";
import { IIntegrationStrategy } from "../../../../core-ui/models/strategies";
import {
  AppointmentChannelEnum,
  ConversationDestinationEnum,
  AgentFeatureEnum,
} from "../../../../core-ui/core-ui.enums";
import { HttpClient } from "@angular/common/http";
import { TalkdeskUser, ITalkdeskUsersPagedResponse } from "../../ITalkdeskUser";
import { firstValueFrom } from "rxjs";
import { ApiResource } from "@auvious/common";
import {
  auviousLicense,
  CLAIM_TALKDESK_ACCESS_TOKEN,
  CLAIM_TALKDESK_REFRESH_TOKEN,
  TALKDESK_API_URL,
} from "../../../app.enums";
import {
  TALKDESK_CSRF_TOKEN_HEADER,
  TALKDESK_CSRF_TOKEN_KEY,
} from "../../applications/TalkdeskApplication";
import { localStore, sessionStore } from "@auvious/utils";

interface ITalkdeskTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  scopes: string;
  refresh_token: string;
  sid: string;
  id_token: string;
}

export class TalkdeskIntegrationStrategy implements IIntegrationStrategy {
  securityService: ApiResource;
  refreshTokenRetries = 0;

  constructor(
    private userService: UserService,
    private httpClient: HttpClient,
    private config: IOpenIDConnectApplicationConfig,
    private rtcService: AuviousRtcService
  ) {
    this.rtcService.common$.subscribe((common) => {
      this.securityService = common.apiResourceFactory("security/talkdesk");
    });
  }

  installIntegration(integration, origin) {
    return null;
  }

  async getIntegrations() {
    return [];
  }

  getIntegrationURL() {
    return null;
  }

  deleteIntegration() {
    return null;
  }

  getIntegrationId() {
    return null;
  }

  getOauthClientId() {
    return null;
  }

  isInstalled() {
    return true;
  }

  supportsInteractionDestination(
    destination: ConversationDestinationEnum
  ): boolean {
    return [ConversationDestinationEnum.STANDALONE].includes(destination);
  }

  supportsInteractionOrigin(origin: ConversationOriginEnum): boolean {
    switch (origin) {
      // enable appointments only if we have a video license
      case ConversationOriginEnum.APPOINTMENT:
        return this.hasLicense("video");
      default:
        return [
          ConversationOriginEnum.CHAT,
          ConversationOriginEnum.WIDGET,
        ].includes(origin);
    }
  }

  supportsAppointmentChannel(channel: AppointmentChannelEnum): boolean {
    return [AppointmentChannelEnum.WEBHOOK].includes(channel);
  }

  supportsAgentFeature(feature: AgentFeatureEnum): boolean {
    return true;
  }

  supportsMemberRoles(): boolean {
    return true;
  }

  getIntegrationIdentifier() {
    return null;
  }

  getIntegrationUserId(user: IUser): string {
    return user.getClaim("talkdesk_user_id");
  }

  getMember(id: string): Promise<IUserDetails> {
    // todo: implement
    return null;
  }

  async getMembers(
    page: number,
    size: number
  ): Promise<IPagedResponse<IUserDetails>> {
    try {
      const request = new TalkdeskRequestBuilder()
        .page(page)
        .size(size)
        .build();

      const response = await firstValueFrom(
        this.httpClient.get<ITalkdeskUsersPagedResponse>(
          `${TALKDESK_API_URL}/users`,
          {
            headers: {
              Authorization: `Bearer ${this.userService
                .getActiveUser()
                .getClaim(CLAIM_TALKDESK_ACCESS_TOKEN)}`,
              "Content-Type": "application/json",
            },
            params: request.getParams(),
          }
        )
      );

      return {
        entities: response._embedded.users.map((m) => new TalkdeskUser(m)),
        pageCount: response.total_pages,
        pageNumber: response.page,
        pageSize: response.per_page,
        total: response.total,
      };
    } catch (ex) {
      // retry only once
      if (ex.status === 401 && this.refreshTokenRetries < 1) {
        try {
          await this.refreshToken();
          return this.getMembers(page, size);
        } catch (err) {
          throw err;
        }
      }
      throw ex;
    }
  }

  async refreshToken() {
    try {
      this.refreshTokenRetries += 1;
      const response = await this.securityService.create(
        {
          refresh_token: this.userService
            .getActiveUser()
            .getClaim(CLAIM_TALKDESK_REFRESH_TOKEN),
        },
        {
          urlPostfix: "refresh_token",
        }
      );

      const tokenResponse: ITalkdeskTokenResponse = response.body;
      const responseHeaders = response.headers;

      if (responseHeaders.get(TALKDESK_CSRF_TOKEN_HEADER)) {
        // store the csrf token in both sessionStorage and localStorage
        sessionStore?.setItem(
          TALKDESK_CSRF_TOKEN_KEY,
          responseHeaders.get(TALKDESK_CSRF_TOKEN_HEADER)
        );
        localStore?.setItem(
          TALKDESK_CSRF_TOKEN_KEY,
          responseHeaders.get(TALKDESK_CSRF_TOKEN_HEADER)
        );
      }

      const user = this.userService.getActiveUser();
      user.setClaim(CLAIM_TALKDESK_REFRESH_TOKEN, tokenResponse.refresh_token);
      user.setClaim(CLAIM_TALKDESK_ACCESS_TOKEN, tokenResponse.access_token);
      this.userService.setActiveUser(user);
    } catch (ex) {
      throw ex;
    }
  }

  hasLicense(key: auviousLicense): boolean {
    switch (key) {
      case "cobrowse":
        return (
          this.userService.getActiveUser().hasLicense("video") ||
          this.userService.getActiveUser().hasLicense("cobrowse")
        );
      case "video":
        return this.userService.getActiveUser().hasLicense(key);
      case "freetrial":
        return true;
      case "screenshare":
        return (
          this.userService.getActiveUser().hasLicense("video") ||
          this.userService.getActiveUser().hasLicense("cobrowse") ||
          this.userService.getActiveUser().hasLicense("screenshare")
        );
    }
  }

  getExportableConfig(config: ITalkdeskApplicationConfig): IApplicationConfig {
    try {
      const deepCopy = JSON.stringify(config);
      const cf: ITalkdeskApplicationConfig = JSON.parse(deepCopy);

      // remove params that are application specific
      delete cf.publicParameters[PublicParam.TALKDESK];
      delete cf.agentParameters[AgentParam.DIGITAL_CONNECT_ENABLED];
      delete cf.openidParameters;
      return cf;
    } catch (ex) {
      return config;
    }
  }
}
