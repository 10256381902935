import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { EventSummary } from "@auvious/cobrowser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-cobrowse-event-item",
  templateUrl: "./cobrowse-event-item.component.html",
  styleUrls: ["./cobrowse-event-item.component.scss"],
})
export class CobrowseEventItemComponent implements OnInit {
  @Input() item: EventSummary;

  @Output() timeTravel: EventEmitter<number> = new EventEmitter();

  constructor(private t: TranslateService) {}

  ngOnInit(): void {}

  get user() {
    return !!this.item.user?.metadata?.name
      ? this.item.user.metadata.name
      : this.item.user?.metadata?.role
      ? this.t.instant(this.item.user.metadata.role)
      : this.item.user?.endpoint;
  }

  get endpoint() {
    return this.item.user?.endpoint;
  }

  goto() {
    this.timeTravel.emit(this.item.time);
  }
}
